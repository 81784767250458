import {BrowserRouter} from "react-router-dom";
import {Toaster} from "react-hot-toast";
import {useContext} from "react";
import {APIContext} from "./contexts/api/APIContext";
import AppRouter from "./routes/AppRouter";
import Footer from "./components/footer/footer";
import Login from "./components/login/login";
import Navigation from "./components/navigation/navigation";

function App() {
    const {loggedIn} = useContext(APIContext)

    return (
        <>
            <BrowserRouter>
                <div><Toaster/></div>
                <Navigation/>
                {!loggedIn && <Login/>}
                {loggedIn && <AppRouter />}
                <Footer />
            </BrowserRouter>
        </>
    );
}

export default App;
