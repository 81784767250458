import {api} from "./api";

const login = (credentials) => {
    return api.post('/v1/users/login', credentials)
};

const logout = () => {
    return api.get('/v1/users/logout')
};

const getGoogleLoginURL = () => {
    return api.get('/v1/google/login')
}

const ping = () => {
    return api.get("/v1/users/ping", {})
}

const roles = () => {
    return api.get("/v1/users/roles", {}).then(r => r.data)
}

const LoginService = {
    login,
    logout,
    getGoogleLoginURL,
    ping,
    roles,
};

export default LoginService;
