import React, {useContext, useState} from 'react';
import './login.css';
import {APIContext} from "../../contexts/api/APIContext";

export default function Login() {
    const {login, loginWithGoogle} = useContext(APIContext)
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const handleSubmit = async e => {
        e.preventDefault();
        login(email, password)
    }

    const handleGoogleLogin = async e => {
        loginWithGoogle()
    }

    return (
        <div className="w-50 m-auto mt-5">
            <form onSubmit={handleSubmit}>
                <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="email">Email:</label>
                    <input type="text" id="email" onChange={e => setEmail(e.target.value)} className="form-control"/>
                </div>

                <div className="form-outline mb-4">
                    <label className="form-label" htmlFor="password">Password:</label>
                    <input type="password" id="password" onChange={e => setPassword(e.target.value)}
                           className="form-control"/>
                </div>

                <div>
                    <button type="submit" className="btn btn-primary mb-4">Sign in</button>
                </div>
            </form>
            <button type="button" onClick={handleGoogleLogin} className="btn btn-primary mb-4">Login with Google
            </button>
        </div>
    )
}
