import {createContext, useCallback, useEffect, useState} from 'react';
import LoginService from "../../services/login";
import toast from "react-hot-toast";

export const APIContext = createContext();

const APIContextProvider = ({children}) => {
    const [loggedIn, setLoggedIn] = useState(false)
    const [roles, setRoles] = useState([])

    const login = (email, password) => {
        LoginService.login({
            email,
            password
        }).catch(() => {
            toast.error("Login error.")
            setRoles([])
            setLoggedIn(false)
        }).then(() => {
            checkLoginStatus()
        })
    };

    const loginWithGoogle = () => {
        LoginService.getGoogleLoginURL().then(data => {
            window.location.href = data.data.url
        })
    }

    const logout = () => {
        LoginService.logout().then(() => {
            checkLoginStatus()
        })
    }

    const checkLoginStatus = useCallback(() => {
        LoginService.roles().then(r => {
            setRoles(r.roles)
            setLoggedIn(true)
        }).catch((r) => {
            if (r.response && r.response.status === 403) {
                setRoles([])
                setLoggedIn(false)
            }
        })
    }, [])

    useEffect(() => {
        checkLoginStatus()

        const interval = setInterval(() => {
            checkLoginStatus()
        }, 10000);

        return () => clearInterval(interval)
    }, [checkLoginStatus])

    const data = {
        loggedIn,
        roles,
        login,
        loginWithGoogle,
        logout,
    }

    return <APIContext.Provider value={data}>{children}</APIContext.Provider>
}

export default APIContextProvider
