import {Container} from "react-bootstrap";

export default function PageNotFound() {
    return (
        <>
            <Container>
                <h1>404 Page not found!</h1>
            </Container>
        </>
    )
}
