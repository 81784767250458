import {Route, Routes} from "react-router-dom";
import {Container} from "react-bootstrap";
import appRoutes from "./routes";
import PageNotFound from "./PageNotFound";

export default function AppRouter() {
    return (
        <Routes>
            <Route path={appRoutes.root} element={
                <Container className="mt-4">
                    <h1>Solar</h1>
                </Container>
            }/>

            <Route path='*' element={<PageNotFound/>}/>
        </Routes>
    )
}
