import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import appRoutes from "../../routes/routes";
import {useContext} from "react";
import {APIContext} from "../../contexts/api/APIContext";

function Navigation() {
    const {loggedIn, logout} = useContext(APIContext)
    const navigate = useNavigate()

    const handleLogout = () => {
        logout()
    }

    let menu = (
        <> </>
    )

    if (loggedIn) {
        menu = (
            <>
                <Nav className="me-auto">

                </Nav>
                <Nav className="ms-auto">
                    <NavDropdown title="Me">
                        <NavDropdown.Item onClick={handleLogout}>Logout</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
            </>
        )
    }

    return (
        <Navbar
            expand="lg"
            variant="dark"
            bg="dark"
        >
            <Container>
                <Navbar.Brand onClick={() => {navigate(appRoutes.root)}}>Solar</Navbar.Brand>
                <Navbar.Toggle aria-controls="main-nav"/>
                <Navbar.Collapse id="main-nav">
                    {menu}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default Navigation;
